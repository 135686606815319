import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'
import {downTemplate} from '@/utils/download'

/**
 * 查询标签列表
 */
export function getTagList(params) {
  return request({
    url: envInfo.bgApp.tagPath + '/tag/page',
    method: 'get',
    params
  })
}

/**
 * 查询标签是否存在
 * @param params
 */
export function tagIsExist(params) {
  return request({
    url: envInfo.bgApp.tagPath + '/tag/tagIsExist',
    method: 'get',
    params
  })
}

/**
 * 查询标签类型列表
 * @param params
 */
export function getTagTypeList(params) {
  return request({
    url: envInfo.bgApp.lookupPath + `/lookup/item/find/list?classifyCode=${params.classifyCode}`,
    method: 'get',
  })
}

/**
 * 删除标签
 * @param params
 */
export function deleteTag(params) {
  return request({
    url: envInfo.bgApp.tagPath + `/tag/delete/${params.tagId}`,
    method: 'delete',
  })
}

/**
 * 批量删除标签
 * @param params
 */
export function batchDeleteTag(params) {
  return request({
    url: envInfo.bgApp.tagPath + `/tag/batchDeleteTag`,
    method: 'delete',
    params
  })
}

/**
 * 更新标签
 * @param params
 */
export function updateTag(params) {
  return request({
    url: envInfo.bgApp.tagPath + `/tag/update`,
    method: 'post',
    data: params
  })
}

/**
 * 新建标签
 * @param params
 */
export function createTag(params) {
  return request({
    url: envInfo.bgApp.tagPath + `/tag/create`,
    method: 'post',
    data: params
  })
}

/**
 * 导出标签列表
 * @param params
 */
export function exportTag(params) {
  return request({
    url: envInfo.bgApp.tagPath + `/tag/export`,
    method: 'post',
    data: params
  })
}

/**
 * 下载设备模版
 */
export function downTagTemplate() {
  downTemplate("ruge.tag", envInfo.bgApp.tagPath);
}
