<template>
  <div class="app-container user-management">
    <div class="filter-line">
      <el-button type="primary" icon="el-icon-plus" @click="regist">
        {{ $t("userManager.regist") }}</el-button
      >
      <el-button
        icon="el-icon-upload"
        type="primary"
        plain
        @click="downTemplate"
      >
        {{ $t("userManager.downTemplate") }}
      </el-button>
      <el-button
        icon="el-icon-upload2"
        type="primary"
        plain
        @click="importUser"
      >
        {{ $t("commons.import") }}
      </el-button>
      <el-button
        icon="el-icon-download"
        type="primary"
        plain
        @click="exportUser"
      >
        {{ $t("commons.export") }}
      </el-button>
      <el-button plain icon="el-icon-refresh-right" @click="getUserList"
        >刷新</el-button
      >
    </div>
    <div class="table-container">
      <FinalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <template v-slot:userNameSlot="row">
          <template v-if="row.datas.edit">
            <el-input
              v-model="row.datas.userName"
              size="small"
              maxlength="25"
              :show-word-limit="true"
              clearable
              :placeholder="$t('commons.pleaseInput')"
            />
          </template>
          <span v-else>{{ row.datas.userName }}</span>
        </template>
        <template v-slot:zoneCodeSlot="row">
          <template v-if="row.datas.edit">
            <template v-if="row.datas.edit">
              <el-select v-model="row.datas.zoneCode">
                <template v-for="item in zoneCodeList">
                  <el-option
                    :key="item.itemId"
                    :label="item.itemName"
                    :value="item.itemCode"
                  ></el-option>
                </template>
              </el-select>
            </template>
          </template>
          <span v-else>{{ row.datas.zoneCode }}</span>
        </template>
        <template v-slot:phoneSlot="row">
          <template v-if="row.datas.edit">
            <el-input
              v-model="row.datas.phone"
              maxlength="11"
              :show-word-limit="true"
              size="small"
              clearable
              :placeholder="$t('commons.pleaseInput')"
            />
          </template>
          <span v-else>{{ row.datas.phone }}</span>
        </template>
        <template v-slot:emailSlot="row">
          <template v-if="row.datas.edit">
            <el-input
              v-model="row.datas.email"
              maxlength="33"
              :show-word-limit="true"
              size="small"
              clearable
              :placeholder="$t('commons.pleaseInput')"
            />
          </template>
          <span v-else>{{ row.datas.email }}</span>
        </template>
        <template v-slot:operationSlot="row">
          <template v-if="row.datas.edit">
            <el-button type="text" @click="updateUser(row.datas)">{{
              $t("commons.save")
            }}</el-button>
            <el-button
              type="text"
              style="color: #ff0000"
              @click="cancelUserEdit(row.datas)"
              >{{ $t("commons.cancel") }}</el-button
            >
          </template>
          <template v-else>
            <el-button type="text" @click="showAllTenant(row.datas)"
              >权限</el-button
            >
            <el-button type="text" @click="editUser(row.datas)">编辑</el-button>
            <el-button type="text" @click="resetPwd(row.datas)"
              >重置密码</el-button
            >
            <el-button type="text" @click="switchAccountStatus(row.datas)">{{
              row.datas.isEnabled == "Y" ? "冻结" : "解冻"
            }}</el-button>
            <el-button type="text" @click="loginLockHandler(row.datas)"
              >解锁</el-button
            >
          </template>
        </template>
      </FinalTable>
    </div>

    <!-- 注册用户 -->
    <el-dialog
      :visible.sync="registModel"
      width="30%"
      :before-close="cancelAddUser"
    >
      <template slot="title">
        <title-icon />{{ $t("userManager.regist") }}
      </template>
      <el-form
        label-position="top"
        :model="user"
        ref="userForm"
        :rules="formRule"
      >
        <el-form-item prop="userAccount">
          <el-input
            v-model="user.userAccount"
            maxlength="40"
            :show-word-limit="true"
            name="userAccount"
            type="text"
            auto-complete="on"
            :placeholder="$t('userManager.userAccount')"
            autofocus
          >
            <template slot="prepend">
              <div>
                <span class="svg-container">
                  <svg-icon icon-class="userAccount" />
                </span>
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="userName">
          <el-input
            v-model="user.userName"
            maxlength="25"
            :show-word-limit="true"
            name="userName"
            type="text"
            auto-complete="on"
            :placeholder="$t('userManager.userName')"
            autofocus
          >
            <template slot="prepend">
              <div>
                <span class="svg-container">
                  <svg-icon icon-class="userIcon" />
                </span>
              </div>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input
            style="width: 70%"
            :maxlength="user.zoneCode === '86' ? 11 : 8"
            :show-word-limit="true"
            v-model="user.phone"
            name="phone"
            type="text"
            auto-complete="on"
            :placeholder="$t('userManager.phone')"
            autofocus
          >
            <template slot="prepend">
              <div>
                <span class="svg-container">
                  <svg-icon icon-class="phoneIcon" />
                </span>
              </div>
            </template>
          </el-input>
          <div style="width: 30%; float: left">
            <el-select v-model="user.zoneCode">
              <template v-for="item in zoneCodeList">
                <el-option
                  :key="item.itemId"
                  :label="item.itemName"
                  :value="item.itemCode"
                ></el-option>
              </template>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item prop="email">
          <el-input
            v-model="user.email"
            maxlength="33"
            :show-word-limit="true"
            name="email"
            type="text"
            auto-complete="on"
            :placeholder="$t('userManager.email')"
            autofocus
          >
            <template slot="prepend">
              <div>
                <span class="svg-container">
                  <svg-icon icon-class="emailIcon" />
                </span>
              </div>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelAddUser()">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="saveUser()"
          :loading="registLoading"
          >{{ $t("commons.save") }}</el-button
        >
      </div>
    </el-dialog>

    <!-- 上传弹框 -->
    <el-dialog :visible.sync="uploadModel" width="40%">
      <template slot="title">
        <title-icon />{{ $t("commons.uploadFile") }}
      </template>
      <div slot="footer">
        <upload
          :ulType="uploadType"
          :excelType="excelType"
          :rootPath="rootPath"
          @success="uploadSuccess"
          @fail="uploadFail"
        ></upload>
      </div>
    </el-dialog>

    <!-- 用户租户角色列表弹框 -->
    <el-dialog :visible.sync="tenantRolesDialog" width="40%">
      <template slot="title">
        <title-icon />{{ $t("tenant.belongingToTenant") }}
      </template>
      <div class="tenant-role-container" v-if="tenantRoleList.length">
        <div
          v-for="(item, index) in tenantRoleList"
          :key="index"
          class="single-tenant"
        >
          <div class="tenant-line">
            {{ item.tenantName }}
          </div>
          <div class="role-line">
            <span
              class="role-span"
              v-for="(role, roleIndex) in item.roleNames"
              :key="roleIndex"
            >
              <img
                src="../../../../assets/images/tenant_role_icon.png"
                alt=""
              />
              {{ role }}
            </span>
          </div>
        </div>
      </div>
      <div v-else class="no-data-container">
        {{ $t("tenant.noBelongingTenant") }}
      </div>
      <div slot="footer">
        <el-button
          type="primary"
          size="small"
          @click="tenantRolesDialog = false"
        >
          {{ $t("commons.close") }}
        </el-button>
      </div>
    </el-dialog>

    <!-- 用户登录解锁弹窗 -->
    <el-dialog
      :visible.sync="loginLock.dialogShow"
      :before-close="loginLockClose"
      width="400"
    >
      <template slot="title"> <title-icon />提示 </template>
      <div v-if="loginLock.loginLock === 0" class="lock-dialog">
        <img src="@/assets/images/login_unlock_icon.png" alt="" />
        <span class="lock-tips"> 当前账户未锁定，无需操作 </span>
        <el-button type="primary" @click="loginLockClose">关闭</el-button>
      </div>
      <div v-else class="lock-dialog">
        <img src="@/assets/images/login_lock_icon.png" alt="" />
        <span class="lock-tips">
          当前账户已锁定，解锁时间剩余
          <span class="last-seconds">
            {{ loginLock.leftLockExpireTime }}
          </span>
          秒
        </span>
        <div>
          <el-button type="primary" @click="unlockUserHandler">解锁</el-button>
          <el-button @click="loginLockClose">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let manager = require("@/api/ruge/sso/manager/user.js");
import FinalTable from "@/components/FinalTable";
import Upload from "@/components/Upload";
import store from "@/store/index";
import { envInfo } from "@/constants/envInfo";

export default {
  name: "user-management-list",
  components: {
    FinalTable,
    Upload,
  },
  data() {
    return {
      loginLock: {
        freshIns: null,
        userAccount: null,
        dialogShow: false,
        loading: false,
        loginLock: 0, //0:未锁定   1:已锁定
        leftLockExpireTime: 0, //剩余锁定时间
      },
      tenantRolesDialog: false,
      tenantRoleList: [],
      uploadModel: false,
      uploadType: "ExcelImport",
      excelType: "ruge.ssoUser",
      rootPath: envInfo.bgApp.ssoPath,
      formRule: {
        userAccount: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            validator: (rule, value, callback) => {
              if (value && value.length > 60) {
                callback(
                  new Error(
                    this.$t("userManager.validMessage.userAccountOutLen")
                  )
                );
              }
              if (!isValidUserAccount(value)) {
                callback(
                  new Error(this.$t("userManager.validMessage.userAccountRegx"))
                );
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
        userName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        phone: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            validator: (rule, value, callback) => {
              console.log("zoneCode", this.user.zoneCode);
              const zoneCode = this.user.zoneCode;
              if (zoneCode === "86") {
                if (value && !validCNPhone(value)) {
                  callback(
                    new Error(this.$t("userManager.validMessage.phoneRegx"))
                  );
                }
              } else if (zoneCode === "852") {
                if (value && !validHKPhone(value)) {
                  callback(
                    new Error(this.$t("userManager.validMessage.HKPhoneRegx"))
                  );
                }
              }

              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
        email: [
          {
            required: false,
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              if (value && !validateEmail(value)) {
                callback(new Error(this.$t("validate.invalidEmail")));
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
      },
      user: {
        userAccount: null,
        userName: null,
        zoneCode: "86",
        phone: null,
        email: null,
      },
      registLoading: false,
      registModel: false,
      zoneCodeList: [],
      listQuery: {
        current: 1,
        rowCount: 10,
        userAccount: null,
        phone: null,
        email: null,
        startDate: null,
        endDate: null,
      },
      loadingFlag: false,
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          {
            prop: "userAccount",
            label: this.$t("userManager.userAccount"),
            width: "",
          },
          {
            prop: "userName",
            label: this.$t("userManager.userName"),
            width: "",
          },
          {
            prop: "zoneCode",
            label: this.$t("userManager.zoneCode"),
            width: "",
          },
          {
            prop: "phone",
            label: this.$t("userManager.phone"),
            width: "",
          },
          {
            prop: "email",
            label: this.$t("userManager.email"),
            width: "",
          },
          {
            prop: "isEnabled",
            label: this.$t("userManager.enabled"),
            width: "",
          },
          {
            prop: "lastUpdateDate",
            label: this.$t("commons.lastUpdateDate"),
            width: "",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "280",
          },
        ],
        tableData: [],
        searchLineConfig: {
          userAccount: {
            type: "input",
            label: "账号",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入账号",
            prefixIcon: "el-icon-search",
          },
          phone: {
            type: "input",
            label: "电话",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入电话",
            prefixIcon: "el-icon-search",
          },
          email: {
            type: "input",
            label: "邮箱",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入邮箱",
            prefixIcon: "el-icon-search",
          },
        },
        // 表格内容配置
        detailConfig: {
          userName: {
            type: "slot",
            slotName: "userNameSlot",
          },
          zoneCode: {
            type: "slot",
            slotName: "zoneCodeSlot",
          },
          phone: {
            type: "slot",
            slotName: "phoneSlot",
          },
          email: {
            type: "slot",
            slotName: "emailSlot",
          },
          lastUpdateDate: {
            type: "dateFormat",
          },
          operation: {
            type: "slot",
            slotName: "operationSlot",
          },
        },
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.getUserList();
    this.initZoneCodeList();
  },
  methods: {
    unlockUserHandler() {
      manager
        .unlockUserStatus({
          userAccount: this.loginLock.userAccount,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: this.$t("message.operationSuccess"),
          });
        })
        .finally(() => {
          this.loginLock.dialogShow = false;
          this.getUserList();
        });
    },
    loginLockHandler(row) {
      manager
        .getUserLockStatus(row ? row.userAccount : this.loginLock.userAccount)
        .then((res) => {
          this.loginLock.loginLock = Number(res.loginLock);
          if (res.leftLockExpireTime) {
            this.loginLock.leftLockExpireTime = Number(res.leftLockExpireTime);
            this.startTimeFresh();
          }
        })
        .finally(() => {
          this.loginLock.userAccount = row.userAccount;
          this.loginLock.dialogShow = true;
        });
    },
    startTimeFresh() {
      this.loginLock.freshIns = window.setInterval(() => {
        if (this.loginLock.leftLockExpireTime > 0) {
          this.loginLock.leftLockExpireTime--;
        } else {
          window.clearInterval(this.loginLock.freshIns);
          this.loginLockHandler();
        }
      }, 1000);
    },
    switchAccountStatus(row) {
      let isEnabled = "Y",
        confrimMsg = "message.unfastenConfirm";
      if (row.isEnabled == "Y") {
        isEnabled = "N";
        confrimMsg = "message.sealConfirm";
      }
      this.$confirm(this.$t(confrimMsg), this.$t("commons.warning"), {
        confirmButtonText: this.$t("commons.confirm"),
        cancelButtonText: this.$t("commons.cancel"),
        type: "warning",
      }).then(() => {
        this.loadingFlag = true;
        manager
          .switchAccountStatus({
            userAccount: row.userAccount,
            isEnabled: isEnabled,
          })
          .then(() => {
            this.getUserList();
          })
          .finally(() => {
            this.loadingFlag = false;
          });
      });
    },
    resetPwd(row) {
      this.$confirm(
        this.$t("message.resetConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      ).then(() => {
        this.loadingFlag = true;
        manager
          .resetPwd({ userAccount: row.userAccount })
          .then((r) => {
            this.$alert(
              this.$t("userManager.message.resetPwd") + r.newPassword,
              this.$t("message.operationSuccess"),
              {
                type: "success",
                confirmButtonText: this.$t("commons.confirm"),
              }
            );
          })
          .finally(() => {
            this.loadingFlag = false;
          });
      });
    },
    updateUser(row) {
      // 真实姓名不能为空
      if (!row.userName || row.userName.trim() === "") {
        this.$message({
          type: "warning",
          message: this.$t("userManager.userNameRequired"),
        });
        return;
      }
      // 手机号不能为空
      if (!row.phone || row.phone.trim() === "") {
        this.$message({
          type: "warning",
          message: this.$t("userManager.phoneRequired"),
        });
        return;
      }
      this.loadingFlag = true;
      manager
        .editUser(row)
        .then((r) => {
          if (r == -1) {
            this.$message({
              type: "error",
              message: this.$t("userManager.message.existPhone"),
            });
          } else {
            row.edit = false;
            this.setOrgUser(row);
          }
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    editUser(row) {
      row.edit = true;
    },
    cancelUserEdit(row) {
      this.resetOrgUser(row);
      row.edit = false;
    },
    resetOrgUser(row) {
      row.userName = row.orgUserName;
      row.email = row.orgEmail;
      row.phone = row.orgPhone;
    },
    showAllTenant({ userAccount }) {
      manager.getUserTenantRoleList({ userAccount }).then((res) => {
        this.tenantRoleList = res;
        this.tenantRolesDialog = true;
      });
    },
    importUser() {
      this.uploadModel = true;
    },
    exportUser() {
      this.loadingFlag = true;
      manager
        .exportUser(this.listQuery)
        .then((msg) => {
          this.$message({
            type: "success",
            message: this.$t("message.operationSuccess"),
          });
          let exportObj = {
            taskId: msg,
            taskName: "SsoUser",
            taskStatus: 0,
            rootPath: "ssoPath",
          };
          //将导出任务丢入导出任务列表中
          store.dispatch("PushExportNotice", exportObj);
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    uploadSuccess() {
      this.uploadModel = false;
      this.$message({
        type: "success",
        message: this.$t("message.uploadSuccess"),
      });
    },
    uploadFail() {
      this.$message({ type: "error", message: this.$t("message.uploadFial") });
    },
    downTemplate() {
      manager.downUserTemplate();
    },
    regist() {
      this.registModel = true;
    },
    cancelAddUser() {
      this.$refs.userForm.resetFields();
      this.registModel = false;
    },
    loginLockClose() {
      this.loginLock.dialogShow = false;
    },
    initZoneCodeList() {
      manager
        .findPhoneZoneCode({ classifyCode: "PHONE_ZONE_CODE" })
        .then((r) => {
          this.zoneCodeList = r;
        });
    },
    getUserList() {
      this.loadingFlag = true;
      manager
        .getUserList(this.listQuery)
        .then((r) => {
          this.dataset.tableData = r.rows.map((v) => {
            this.$set(v, "edit", false);
            this.setOrgUser(v);
            return v;
          });
          this.dataset.pageVO.total = r.total;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    setOrgUser(row) {
      row.orgUserName = row.userName;
      row.orgEmail = row.email;
      row.orgPhone = row.phone;
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.listQuery = { ...this.listQuery, ...datas.params };
        this.getUserList();
      } else if (datas.type === "paginationChange") {
        this.listQuery.current = datas.params.current.page;
        this.listQuery.rowCount = datas.params.current.limit;
        this.getUserList();
      }
      // else if (datas.type === "iconClick") {
      //   switch (datas.eventName) {
      //     case "view":
      //       this.viewDeviceDetail(datas.row);
      //       break;
      //   }
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.user-management {
  .table-container {
    margin-top: 15px;
  }
  .tenant-role-container {
    padding-left: 10px;
    .single-tenant {
      margin-bottom: 15px;
      .tenant-line {
        font-size: 14px;
        font-weight: 600;
        color: #333;
        margin-bottom: 10px;
      }

      .role-line {
        font-size: 14px;
        color: #333333;
        font-weight: 400;
        display: flex;
        .role-span {
          background: #e5f2fb;
          padding: 6px;
          margin-right: 10px;
          border-radius: 40px;
          display: flex;
          align-items: center;
          img {
            margin-right: 3px;
          }
        }
      }
    }
  }
  .no-data-container {
    font-size: 14px;
    padding-left: 10px;
  }
  .lock-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    .lock-tips {
      font-weight: 400;
      font-size: 16px;
      color: #5d687c;
      margin: 40px 0 60px;
      .last-seconds {
        color: #ff0000;
      }
    }
  }
}
</style>